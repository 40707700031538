
.planBody {
    background-color: #fff;
    height: auto;
    width: auto;
    padding: 0px 50px 10px 50px;
    margin: 20px;
    padding: 10px 10px;
    border-radius: 10px;
    box-shadow: 0 .5rem .5rem rgba(0,0,0,.2)!important;
    cursor: pointer;
    font-weight: bold;
}

.card {
	border: none !important;
}

.cardTitle {
	font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.rateCard {
	border: none !important;
}

.rateCardHeader {
	padding: .3rem 1.25rem
}