.appHeader {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .nav {
    width: auto;
    justify-content: end;
    color: #7EB95B;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }