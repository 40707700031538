.body {
    display: flex;
    /* position: fixed; */
    height: calc(100vh - 10vmin);
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    padding-right: 20px;
    font-size: calc(2px + 2vmin);
    /* background-color: red; */
    overflow-y: scroll;
}