.formBody {
    background-color: #FFFFFF;
    padding: 0px 10px 10px 10px;
    /* margin: 20px; */
    margin-top: 0px;
    margin-top: 0px;
}
.button {
    font-size: 18px !important;
}

.buttonRegister {
    font-size: 18px !important;
    color: #000000 !important;
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;
}

.errorMsg {
    color: red;
    text-transform: capitalize;
}

  