.card {
    background-color: #fff;
    height: auto;
    width: 100%;
    margin: 30px 10px;
    padding: 10px 10px;
    border-radius: 20px;
    box-shadow: 0 .25rem .2rem rgba(0,0,0,.15)!important;
    text-transform: uppercase;
}

.card p {
    margin: 5px;
    font-size: 13px;
}

.card h2 {
    font-weight: bold;
}