.sideBarBody {
    display: flex;
    height: calc(100vh - 10vmin);
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    padding: 20px 0px 10px 10px;
    margin-left: 20px;
    overflow-y: scroll;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: 100%;
}
.bg-content {
    
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    background-position-x: -5%;
    background-position-y: 0%;
    background-size: 150%;
}

.card {
    color: #000000a7;
    background-color: #fff;
    height: auto;
    width: 100%;
    margin-top: 5px;
    padding: 10px 10px;
    border-radius: 10px;
    box-shadow: 0 .25rem .2rem rgba(0,0,0,.05)!important;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
}

.button {
    font-size: 18px !important;
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    border-radius: 0px !important;
}

.card p {
    margin: 5px;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.card h2 {
    font-weight: bold;
}

.dropdownToggle {
    text-overflow: ellipsis;
    max-width: 170px;
    width: 170px;
    overflow: hidden;
}

.dropdownToggle::after {
    position: absolute;
    right: 10%;
    top: 50%;
}

