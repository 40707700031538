@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat|Open+Sans:300,400&display=swap');

body {
    font-family: 'Lato', sans-serif;
    margin: 0 auto;
    /*height: 100%;*/
    /*min-height: 100%;*/
    /*max-height: 100%;*/
    overflow: hidden;
    color: #818282 !important;
}

input {
    height: 50px !important;
}

a {
    color: #EFE60E;
}
hr {
  border-color: #EFE60E;
}

button {
  color: #000000 !important;
  background: #ffffff !important;
  background-color: #ffffff !important;
  border-color: #EFE60E !important;
  border-radius: 10px !important;
}

.table-button {
  padding: 3px 10px !important;
  margin-bottom: 3px !important;
  font-size: 12px !important;
  border-radius: 5px !important;
}

a:hover {
color: #E34D34;
}

.error {
  width: 100%;
  color: red;
}
.success {
  width: 100%;
  color: green;
}

.body {
  padding: 20px 0px 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh
}

.content {
    width: 100%;
}

.form-content {
    border-radius: 20px !important;
    padding-left: 3rem;
    padding-right: 3rem;
}

.page-title {
  font-weight: Bold;
  text-transform: capitalize;
}

.role {
  font-weight: 300;
}
/* HEADER */
  nav {
    height: 65px !important;
  }
.nav-link {
  color: #EFE60E !important;
}



/* FORM */

.form-group {
    position: relative !important;
    margin-bottom: 1.5rem !important;
  }
  
  .form-control-placeholder {
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    font-size: 16px;
    margin-left: 10px;
    padding: 7px 0 0 10px;
    transition: all 200ms;
    opacity: 0.7;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 50px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff !important;
    border: 1px solid #EFE60E !important;
  }

  .form-control:focus {
    border-color: #EFE60E !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(182, 255, 133, 0.267) !important;
}

  
  
  .form-control:focus + .form-control-placeholder,
  .form-control:valid + .form-control-placeholder,
  input[value]:not([value=""]) ~ label,
  input:focus:not(:placeholder-shown) ~ label {
    font-size: 90%;
    padding: 0px 15px 0 5px;
    margin: 0px 0 0 20px;
    transform: translate3d(0, -80%, 0) !important;
    opacity: 1;
    font-size: 16px;
    font-weight: 500;
  }

  .search-container {
    width: 70%;
    /* margin-left: 15%; */
    justify-content: center;
  }

  .search-input {
    font-size: 20px !important;
    /* font-weight: bold !important; */
    height: 50px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-color: #EFE60E !important;
    color: #818282 !important;
  }

  .search-input::placeholder{
    color: #EFE60Ea5 !important;
  }

  .search-input-button {
    height: 50px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    color: #FFFFFF !important;
    background-color: #EFE60E !important;
    border-color: #EFE60E !important;
    padding: 10px 20px !important;
  }

  /* TABLE */
  .table {
    color: #000000 !important;
    border-collapse: separate;
  }

  .table-bordered thead th {
    font-family: 'Montserrat', sans-serif;
    border-bottom-width: 20px;
    border: 0px;
    font-size: 11px;
    text-align: center;
    color: #000000a5
}

.table-bordered td {
  border: 1px solid #ebebeb;
}

.no-border {
  border: 0px solid #ebebeb !important;
}

.td-border-left {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.td-border-right {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* PAGINATION */
.page-link {
  color: #EFE60E !important;
}

@media only screen and (max-width: 600px) {
  .form-content {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}